<template>
  <div>
    <a-page-header title="榜单数据管理" class="hearStyle" />
    <a-form layout="inline">
      <a-form-item label="榜单项目:">
        <a-select placeholder="请选择榜单项目" v-model="searchForm.projectId" style="width: 200px"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="option in projectList" :key="option.id" :value="option.id">
            {{ option.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="榜单更新时间：">
        <a-select placeholder="请选择榜单更新时间" v-model="searchForm.updateTime" style="width: 200px"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="option in periodList" :key="option.value" :value="option.label">
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
      </span>
    </a-form>
    <a-button v-if="$checkButtonPermissions($buttonPermissionsConfig.ranking.rankingData.edit)" class="mt-15" type="primary"
      style="background-color: #e6a23c; color: #fff; border: #e6a23c;" @click="exportData"> 导出
    </a-button>
    <a-table :columns="columns" style="margin-top: 10px" :data-source="tableData" :rowKey="(record, index) => {
      return index;
    }
      " :pagination="pagination.total ? pagination : false" @change="pageChange" :scroll="{ x: 1500 }" bordered>
      <span slot="customScore" slot-scope="text, record" class="action">
        {{ text.customScore ? `${text.customType === 1 ? '-' : '+'}${text.customScore}` : '0' }}
      </span>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button v-if="$checkButtonPermissions($buttonPermissionsConfig.ranking.rankingData.edit) && record.rankNumber === newestUpdateTime" type="link" @click="check(record)">自定义调整</a-button>
      </span>
    </a-table>
    <EditIndex v-if="visible" :show.sync="visible" :id="id" @cancel="visible = false" :actualScore="selectActualScore"
      @refresh="getTableList()" />
  </div>
</template>
<script>

import EditIndex from './edit/index.vue'
import * as api from "@/api/ranking";
import { mapState } from "vuex";
import { dealExcelRespose } from '@/utils/download'
import {projectName} from "@/api/serviceBinding";
export default {
  components: { EditIndex },
  computed: {
    ...mapState({
      projectList: (state) => state.common.projectList
    }),
  },
  watch: {
    projectList: {
      handler(newVal) {
        if (newVal && newVal.length) {
          this.searchForm.projectId = newVal[0].id;
          this.currentProjectName = newVal[0].project_name;
          this.periodList = [];
          this.getPeriodList();
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      visible: false,
      currentUserId: undefined,
      selectActualScore: undefined,
      id: undefined,
      newestUpdateTime: undefined,
      searchForm: {
        projectId: undefined,
        updateTime: undefined
      },
      //分页数据
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      columns: [
        { title: "排名", dataIndex: "ranking", key: "ranking", width: 100, align: "center" },
        { title: "商户名称", dataIndex: "shopName", key: "shopName", align: "center" },
        { title: "本周订单量", dataIndex: "orderSum", key: "orderSum", align: "center" },
        { title: "订单分数", dataIndex: "orderSumScore", key: "orderSumScore", align: "center" },
        { title: "实际分数", dataIndex: "actualScore", key: "actualScore", align: "center" },
        { title: "自定义分值", key: "customScore", align: "center", scopedSlots: { customRender: "customScore" } },
        { title: "总分值", dataIndex: "totalScore", key: "totalScore", align: "center" },
        { title: "操作时间", dataIndex: "updateTime", key: "updateTime", align: "center" },
        { title: "操作人", dataIndex: "updateUser", key: "updateUser", align: "center" },
      ],
      tableData: [],
      // 列表操作按钮权限配置
      actions: [
        this.$buttonPermissionsConfig.ranking.rankingData.edit,
      ],
      periodList: [],
      init: true
    };
  },
  mounted() {
  },
  methods: {
    // 关闭弹窗
    handleCancel(e) {
      this.visible = false;
    },
    //查看打开弹窗
    check(val) {
      this.visible = true
      this.selectActualScore = val.actualScore;
      this.id = val.id;
    },
    // 导出
    async exportData() {
      try {
        const res = await api.exportRankingList(this.findOrExportDataDispose());
        dealExcelRespose(res.data, '榜单数据.xlsx');
      } catch (error) {

      }
    },
    //编辑添加打开弹出
    edmit(val) {
      // 判断弹窗类型是否是系统弹窗
      if (val.systemBounced === 0) {
        this.$router.push({
          path: "/popupAdmin/editPopup",
          query: { id: val.popupId, type: "0" },
        });
      } else {
        //非系统
        this.$router.push({
          path: "/popupAdmin/addPopup",
          query: { id: val.popupId, type: "1" },
        });
      }
    },

    //筛选
    search() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getTableList();
    },
    // 筛选重置
    resetSearchForm() {
      this.searchForm = {
        updateTime: this.periodList.length ? this.periodList[0].label : undefined,
        projectId: this.searchForm.projectId
      }
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getTableList();
    },
    //确认禁用
    changeTypeDisabled(record) {

    },
    //确认启用
    changeTypeEnable(record) {
    },
    //获取列表数据
    async getTableList() {
      const res = await api.findRankingList(this.findOrExportDataDispose());
      if (res.code === this.$SUCCESS_CODE) {
        this.tableData = res.data?.items || [];
        this.pagination.total = res.data?.total;
      }
    },
    //分页操作
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getTableList();
    },
    // 获取周期列表
    async getPeriodList() {
      try {
        const res = await api.getRankingPeriodList();
        if (res.code === this.$SUCCESS_CODE) {
          if (res.data && res.data.length) {
            res.data.forEach((item, index) => {
              if (item) {
                this.periodList.push({
                  label: item,
                  value: index
                });
              }
            });
            this.searchForm.updateTime = this.periodList.length ? this.periodList[0].label : undefined;
            this.newestUpdateTime = this.periodList.length ? this.periodList[0].label : undefined;
            console.log(this.newestUpdateTime)
            this.getTableList();
          }
        }
        console.log(res);
      } catch (error) {

      }
    },
    /**数据处理 */
    findOrExportDataDispose() {
      return {
        ...this.searchForm,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      }
    }
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.columns.push(this.$backTableAction({ align: "center" }));
  }
};
</script>
<style lang="scss" scoped>
.mt-15 {
  margin-top: 15px;
}

.hearStyle {
  padding-left: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 237, 240);
}

.ant-table-row .ant-btn {
  padding: 0;
  margin: 0 5px;
}

p {
  margin-bottom: 0px;
}
</style>